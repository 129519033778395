// import profile images
import ProfilePhoto1 from "../src/assets/img/profile1.png";
import ProfilePhoto2 from "../src/assets/img/profile2.png";
import ProfilePhoto3 from "../src/assets/img/profile3.png";

// import about icons
import Skill1 from "../src/assets/img/skills/skill1.png";
import Skill2 from "../src/assets/img/skills/skill2.png";
import Skill3 from "../src/assets/img/skills/skill3.png";
import Skill4 from "../src/assets/img/skills/skill4.png";
import Skill5 from "../src/assets/img/skills/skill5.png";
import Skill6 from "../src/assets/img/skills/skill6.png";

// import team images
import TeamMember1 from "../src/assets/img/lawyer1.png";
import TeamMember2 from "../src/assets/img/lawyer2.png";
import TeamMember3 from "../src/assets/img/lawyer3.png";

// import social icons
import FacebookIcon from "../src/assets/img/social/facebook.png";
import TwitterIcon from "../src/assets/img/social/twitter.png";
import InstagramIcon from "../src/assets/img/social/instagram.png";
import YoutubeIcon from "../src/assets/img/social/youtube.png";
import PinterestIcon from "../src/assets/img/social/pinterest.png";

// navigation data
export const navigation = [
  {
    name: "Home",
    href: "home",
  },
  {
    name: "About Us",
    href: "about",
  },
  {
    name: "Our Expertise",
    href: "skills",
  },
  {
    name: "Team",
    href: "team",
  },
  {
    name: "Publications",
    href: "publications",
  },
  {
    name: "Contact us",
    href: "contact",
  },
];

// testimonials data
export const testimonials = [
  {
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem rem ad consequuntur similique sequi illum dolorum omnis inventore labore odio!",
    name: "Jane Cooper",
    image: ProfilePhoto1,
    position: "Web Developer",
  },
  {
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem rem ad consequuntur similique sequi illum dolorum omnis inventore labore odio!",
    name: "Theresa Webb",
    image: ProfilePhoto2,
    position: "Web Designer",
  },
  {
    text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorem rem ad consequuntur similique sequi illum dolorum omnis inventore labore odio!",
    name: "Kathryn Murphy",
    image: ProfilePhoto3,
    position: "UI/UX Designer",
  },
];

// skills data
export const skills = [
  {
    icon: Skill1,
    title: "Corporate Transaction Advisory",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
  {
    icon: Skill2,
    title: "Corporate Commercial",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
  {
    icon: Skill3,
    title: "Mergers and Acquisitions",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
  {
    icon: Skill4,
    title: "Private Equity",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
  {
    icon: Skill5,
    title: "Labour & Employment",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
  {
    icon: Skill6,
    title: "Foreign Direct Investment",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
  {
    icon: Skill1,
    title: "Fintech Solutions",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
  {
    icon: Skill2,
    title: "Capital Restructuring",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
  {
    icon: Skill3,
    title: "Commercial Contracts",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
  {
    icon: Skill4,
    title: "Online Gaming & E-sports",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
  {
    icon: Skill5,
    title: "Company Secretarial",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
  {
    icon: Skill6,
    title: "Privacy and Data Protection",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
  {
    icon: Skill1,
    title: "Regulatory & Compliance",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
  {
    icon: Skill2,
    title: "Media and Entertainment",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
  {
    icon: Skill3,
    title: "Sports Law",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
];

// team data
export const team = [
  {
    image: TeamMember1,
    name: "Jane William",
    position: "Band & Financial Lawyer",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
  {
    image: TeamMember2,
    name: "Andrea Collins",
    position: "Family Lawyer",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
  {
    image: TeamMember3,
    name: "John Smith",
    position: "Business Lawyer",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.",
  },
];

// // social data
// export const social = [
//   {
//     icon: FacebookIcon,
//   },
//   {
//     icon: TwitterIcon,
//   },
//   {
//     icon: InstagramIcon,
//   },
// ];
